/* assistant-regular - latin */
@font-face {
    font-family: 'Assistant';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/assistant-v5-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Assistant'), local('Assistant-Regular'),
    url('../fonts/assistant-v5-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('../fonts/assistant-v5-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('../fonts/assistant-v5-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('../fonts/assistant-v5-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('../fonts/assistant-v5-latin-regular.svg#Assistant') format('svg'); /* Legacy iOS */
}
@font-face {
    font-family: Gloria;
    font-style: normal;
    font-weight: 400;
    src:
      url('../fonts/GloriaHallelujah-Regular.woff2') format('woff2'),
      url('../fonts/GloriaHallelujah-Regular.woff') format('woff'),
      url('../fonts/GloriaHallelujah-Regular.ttf') format('truetype');
}

@font-face {
    font-family: Poiret;
    font-style: normal;
    font-weight: 400;
    src:
      url('../fonts/PoiretOne-Regular.woff2') format('woff2'),
      url('../fonts/PoiretOne-Regular.woff') format('woff'),
      url('../fonts/PoiretOne-Regular.ttf') format('truetype');
}


html {
    width: 100%;
    height: 100%;
    overflow: auto !important;
    font-size: 16px;
}

body {
    font-family: Assistant, serif;
    font-size: 16px;
    width: 100%;
    height: 100%;
    color: #000000A5;
    scroll-behavior: smooth;
    overflow: auto !important;
}

#root {
    width: 100%;
    height: 100%;
}

.black { color: #000; }

.textRight { text-align: right; }
.textLeft { text-align: left; }
.textCenter { text-align: center; }

.inline { display: inline; }
.block { display: block; }
.inlineBlock { display: inline-block; }

.height100 { height: 100%; }

.width100 { width: 100%; }
.width80 { width: 80%; }
.width60 { width: 60%; }

.pointer { cursor: pointer; }
.hidden { display: none; }

.paddingBottom24 {
    padding-bottom: 24px;
}

.relative { position: relative; }

.smallLabel {
    font-size: 12px;
}
.bold { font-weight: bold; }

.flexCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.downloadArea {
    width: 100%;
    border: none;
    height: 256px;
    position: relative;
    overflow: hidden;
}


.ant-message-notice {
    text-align: right;
}
.ant-message-notice-content {
    text-align: left;
}
.ant-message-notice div[class*="ant-message-"],
.ant-message-notice div[class^="ant-message-"] {
    min-width: 500px;

}

.circleIcon {
    border-radius: 100%;
    width: 20px;
    height: 20px;
    background-color: #333;
    color: #fff;
    line-height: 24px;
    font-size: 10px;
}

.circleIcon.success {
    background-color: #52c41a;
}

.circleIcon.error {
    background-color: #e1001a;
}

.errorTxt {
    color: #e1001a;
}

.inputArea {
    max-width: 786px;
    width: 100%;
    margin: 0 auto;
}

.layoutWrapper {
    min-height: 100vh;
    width: calc(100vw - 256px);
    position: relative;
}

h1, h2, h3, h4 {
    font-family: Poiret, serif;
}

h2 { font-size: 32px; }

.smallLabel {
    font-size: 12px;
}

pre {
    display: inline;
    font-size: 90%;
}

.dndElement {
    min-height: 20px;
    border: solid 1px #414141;
    margin-bottom: 6px;
    border-radius: 4px;
    padding: 4px 2px;
    background-color: #fff;
}

.selectLocationSuggestionWrapper {
    display: block;
    margin-left: 0px;
    margin-right: 0px;
    line-height: 35px;
    box-shadow:  0 2px 8px rgba(0, 0, 0, 0.15);
    border-color: transparent;
    position: absolute;
    z-index: 10;
    background-color: #fff;
    border-radius: 5px;
}
.selectLocationSuggestion-active {
    position: relative;
    display: block;
    padding: 5px 12px;
    line-height: 22px;
    font-weight: 400;
    background-color: #e6f7ff;
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
}
.selectLocationSuggestion {
    position: relative;
    display: block;
    padding: 5px 12px;
    line-height: 22px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    white-space: nowrap;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-transition: background 0.3s ease;
    transition: background 0.3s ease;
}


.required {
    color: #e1001a  !important;
}

sup.required {
    font-size: 18px;
    top: 0;
}

.reductionInformation {
    font-style: italic;
    font-size: 12px;
    margin-top: 20px;
    line-height: 15px;
    display: inline-block;
}

.custom-tooltip {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 10px;
    color: #fff;
}

.flexLine, .displayFlex {
    display: flex;
}

.column { flex-direction: column; }

.flexEntry {
    flex: 1;
}

.marginTopAuto { margin-top: auto; }
.marginBottomAuto { margin-top: auto; }

.holidayField {
    padding-left: 40px;
}

.holidayField.disabled {
    position: relative;
}

.holidayField.disabled:after {
    background-color: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: -25px;
    left: 0;
    width: 70%;
    height: calc(100% + 44px);
    z-index: 10;
    content: '';
}

.card {

}

.card .ant-list-header {
    padding: 0;
}

.card h2 {
    font-weight: bold;
    margin-bottom: 0;
}